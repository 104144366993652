
/****************************************************
********************* aboutUs ***********************
****************************************************/
.aboutUs {
    background-color: #917758;
    padding-left: 15px;
    padding-right: 15px;
}

.flex-wrap{
    display: flex;
    flex-wrap: wrap;
}

.aboutUs-content {
    /*Center content*/
    width: 100%; /* width should be 65% of the screen */
    max-width: 1170px; /* max 1170px (if using big screen) */
    margin: 0 auto;
    padding-bottom: 5rem;
}

/**************** left side ****************/

.about-left-side {
    /* margin-right: 6rem; */
    margin: 0 auto;
}

.rectangle-area {
    /* background-color: aqua; */
    width: 25rem; /* 50% of the 40% (the left side) will be the image */
    height: 50rem;
    position: relative;
    /*left: 11rem; /* left side of image will start from 45% of the width of left side (which is 40%) */
    top: -4em; /* Top side of image will be placed at '-3em', so it overlaps the screen above it */
}

.rectangle-area-mobile {
    /* background-color: aqua; */
    width: 22rem; /* 50% of the 40% (the left side) will be the image */
    height: 51rem;
    position: relative;
    /*left: 11rem; /* left side of image will start from 45% of the width of left side (which is 40%) */
    top: -4em; /* Top side of image will be placed at '-3em', so it overlaps the screen above it */
}

.rectangle-image {
    width: 100%; /* Image width is 100% of the rectangle are width */
    height: 100%; /* Image height is 100% of the rectangle are height */
}

.rectangle-content {
    background-color: rgba(0, 0, 0, 0.9);
    width: 85%; /* 95% of the rectangle-area width */
    height: 93%; /* 95% of the rectangle-area height */

    position: absolute; /* so we can define its positions using left & top */
    left: 7.5%; /* 100%-85%=15%    15%/2=7.5%  */
    top: 3.5%; /* 100%-93%=7%     7%/2=3.5%   */
}


/**************** right side ****************/

.about-right-side {
    font-size: 25px;
    position: relative;
    display: flex;
    margin: 0 auto;
    margin-top: 5rem;
}

.about-title-1 {
    color: white;
    font-family: 'Poppins', sans-serif;
}

.about-title-2 {
    color: white;
    font-family: 'Poppins', sans-serif;
    width: 30rem; 
}

.line:after {
    display: block;
    
    content: "";
    background: #a38f78;
    /* width: 70px; */
    width: 420px;
    height: 4px;

    margin-top: .5rem;
    margin-bottom: 2.5rem;
}

.text-left {
    color: #d2ccc5;
    max-width: 28rem;
    margin-right: 2em;
}

.p1 {
    font-size: 18px;
    margin: 1rem 0;
}

.p2 {
    font-size: 13px;
}

.p1-mobile {
    font-size: 18px;
    margin: 1rem 0;
    max-width: 22rem;
}

.p2-mobile {
    font-size: 13px;
    max-width: 22rem;
}

.brush-image {
    margin-top: 13rem;
    width: 7em; 
    opacity: 0.70;
}



/**************** rectangle content (left side) ****************/

.icon-area {
    width: 34px;
    margin: 0 auto;
    margin-top: 3rem;
}

.timing-icon-big {
    width: 50px;
    /* margin: 0 auto; */ /* not needed as it will fill the 'icon-area' */
}

.timing-icon {
    width: 35px;
    /* margin: 0 auto; */ /* not needed as it will fill the 'icon-area' */
}

.timing {
    text-align: center;
    color: #917758;
    font-size: 33px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.lineTiming:after {
    display: block;
    
    content: "";
    background: #917758;
    width: 16rem;
    height: 2px;
    
    margin: 0 auto;
}




.timing-title {
    margin-top: 2.8rem;
    color: #88837d;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 600;
}

.timing-content {
    color: white;
}

