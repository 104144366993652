
/****************************************************
********************* home - parallax ***********************
****************************************************/


body, html { /* Make body have 100% of the browser height */
    height: 100vh;
    /* color: #777; */
    line-height: 1.8;
  }
  
  /* Create a Parallax Effect */
  .parallax-effect {
      background-attachment: fixed; /* Set background image to fixed (don't scroll along with the page) */
      background-position: center; /* Center the background image */
      background-repeat: no-repeat; /* Set the background image to no repeat */
      background-size: cover; /* Scale the background image to be as large as possible */
  }
  
  /* home image */
  .img-home {
      background-image: url('../../../../images/parallax3.jpg');
      min-height: 100vh; /* set minimum height to minimum 100% of device screen height */
  }
  
  .logo-normal {
      height: 350px;
      /* height: 300px; */
      /* margin-top: 8%; */
  }
  
  .logo-small {
      height: 150px;
  }
  
  .opacity-75 {
      /* opacity: 0.75; */
      opacity: 0.85;
  }
  
      /* Turn off parallax scrolling for tablets and phones */
      /* @media only screen and (max-device-width: 1600px) {
          .img-home {
          background-attachment: scroll;
          min-height: 400px;
          }
      } */
  
  
  .center {
      position: absolute; /* Absolute positioning means that the element is taken completely out of the normal flow of the page layout. As far as the rest of the elements on the page are concerned, the absolutely positioned element simply doesn't exist. The element itself is then drawn separately, sort of "on top" of everything else, at the position you specify using the left, right, top and bottom attributes. */
      top: 50%; /* position the top  edge of the element at the middle of the parent */
      left: 50%; /* position the left edge of the element at the middle of the parent */
      transform: translate(-50%, -50%); /* so not the edges of the content is centered but the whole content */
      -ms-transform: translate(-50%, -50%); /* as above, but for Internet Explorer */
      -ms-transform: translate(-50%, -50%); /* as above, but for firefox */
  }
  
  @keyframes opacityAnimation {
      from {
          opacity: 0 /* from 0 (opacity = 0%) */
      }
      to {
          opacity: 1 /* to 1 (opacity = 100%) */
      }
  }
  
  .animate-opacity {
      animation: opacityAnimation 1s; /* we call our 'opacityAnimation' that we specified above */
  }
  
  