
/****************************************************
********************* Footer ***********************
****************************************************/


.footer {
    background-color: #191919;
    /* height: 10rem; */
}




/* home image */
.footer-content {
    padding-top: 5rem;
    /* background-color: #191919; */
    padding-bottom: 5rem;
    /*height: 50em; /* Delete later */

    text-align: center;
}

.footer-text {
    color: #f3f3f3;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1.5px;
}

.footer-icons {
    /* margin-top: 1rem; */
    /* height: 10rem; */

    /* margin-left: 5rem; */
    width: 8rem;
    margin: 1rem auto;
}


.circle-border {
    border-radius: 100%;
    border: 1px solid #636d79;

    width: 45px;
    height: 45px;
    padding-top: 10px; /* so the icons will be more in center */

    margin-right: 1rem;

    cursor: pointer;

    transition: all ease 0.5s;
}

.circle-border:hover {
    background-color: #917758;
}

.social-media-icon {
    height: 1.5rem;
    margin: auto auto;
}


