/* Import Poppins font from api */
@import url('https://fonts.googleapis.com/css?family=Poppins:300&display=swap');

/******** General CSS Confugurations ********/

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.overflow {/* The overflow property specifies what should happen if content overflows an element's box. (Fixed the 'white empty' are shown on mobile devices) https://www.w3schools.com/cssref/pr_pos_overflow.asp */
    overflow: hidden; /* we have made the overflow to 'hidden' in the 'OnePageApplication' (see 'OnePageApplication.js' file). (Admin area doesen't have overflow to hidden, which means, that if there are elements not mobile friendly (which needs to be fully displayed), then we make it possible to scroll left/right on that page. */
    /* overflow: auto; */
}

html, body {
    font-family: Verdana,sans-serif;
    font-size: 15px;
    line-height: 1.5;
    /* height: 100%; */
    /* color: #777; */
}



/****************************************************
********************* Device width (hide) ***********************
****************************************************/



@media (max-width:600px) { /* If width of device is 600px or below, then do this */
    .hide-small {
        display: none!important;
    }

    .w3-hide-small {
		display: none!important
	}
}

@media (max-width:992px) and (min-width:601px) { /* If width of device is between 601px-992px, then do this */
	.hide-medium {
		display: none!important;
	}
}

@media (min-width:993px) { /* If width of device is more than 993px, then do this */
	.hide-large {
		display: none!important;
	}
}


/******************************************************/
