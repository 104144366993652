@import url(https://fonts.googleapis.com/css?family=Poppins:300&display=swap);
/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*/
/* Import Poppins font from api */

/******** General CSS Confugurations ********/

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.overflow {/* The overflow property specifies what should happen if content overflows an element's box. (Fixed the 'white empty' are shown on mobile devices) https://www.w3schools.com/cssref/pr_pos_overflow.asp */
    overflow: hidden; /* we have made the overflow to 'hidden' in the 'OnePageApplication' (see 'OnePageApplication.js' file). (Admin area doesen't have overflow to hidden, which means, that if there are elements not mobile friendly (which needs to be fully displayed), then we make it possible to scroll left/right on that page. */
    /* overflow: auto; */
}

html, body {
    font-family: Verdana,sans-serif;
    font-size: 15px;
    line-height: 1.5;
    /* height: 100%; */
    /* color: #777; */
}



/****************************************************
********************* Device width (hide) ***********************
****************************************************/



@media (max-width:600px) { /* If width of device is 600px or below, then do this */
    .hide-small {
        display: none!important;
    }

    .w3-hide-small {
		display: none!important
	}
}

@media (max-width:992px) and (min-width:601px) { /* If width of device is between 601px-992px, then do this */
	.hide-medium {
		display: none!important;
	}
}

@media (min-width:993px) { /* If width of device is more than 993px, then do this */
	.hide-large {
		display: none!important;
	}
}


/******************************************************/



/****************************************************
********************* Nav ***********************
****************************************************/

.nav {
    position: fixed; /* An element with position: fixed; is positioned relative to the viewport, which means it always stays in the same place even if the page is scrolled. The top, right, bottom, and left properties are used to position the element. */
    width: 100%; /* fills/stretches the whole width */
    z-index: 1; /* The z-index property specifies the stack order of an element. An element with greater stack order is always in front of an element with a lower stack order. Note: z-index only works on positioned elements (position: absolute, position: relative, position: fixed, or position: sticky).*/
    /* top: 0; */
    /* border-top: 6px solid #ccc!important; */
    /* text-align: center; */
}

.nav-on-scroll {
    color: #000!important;
    background-color: #fff!important;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}


.normal-nav {
    text-align: center;
    padding-top: .75em;
    padding-bottom: 1em;
    /* background-color: antiquewhite; */
}

.nav-btn {
    padding: 1em 1em;
    vertical-align: middle;
    overflow: hidden; /* The overflow is clipped, and the rest of the content will be invisible */
	text-decoration: none;
	color: #777; /* inherit its value from its parent element. */
	/*background-color: inherit; /* inherit its value from its parent element. */
    /*     
    -webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
     */
    white-space: normal; /*  Lines are broken as necessary to fill line boxes */

    -webkit-transition: all ease 0.5s;

    transition: all ease 0.5s;
}

.nav-btn:disabled {
	cursor: not-allowed;
	opacity: 0.3
}

.nav-btn:hover {
	color: #000!important;
	background-color: #ccc!important
}

.right {
    float: right!important;
}


/******************* Mini nav **********************/

.mini-nav{
    text-align: left;
}

.mini-nav-btn {
    width: 100%;
    display: block;
    text-decoration: none;
    color: #777;
    /* background-color: inherit; */
    padding: 8px 16px;

    -webkit-transition: all ease 0.5s;

    transition: all ease 0.5s;
}

.mini-nav-btn:hover {
    color: #000!important;
	background-color: #ccc!important
}

.links-hide {
	display: none!important
}

.links-show {
	display: block!important
}

.mini-nav-background {
	color: #000!important;
	background-color: #fff!important
}


/****************************************************
********************* home - parallax ***********************
****************************************************/


body, html { /* Make body have 100% of the browser height */
    height: 100vh;
    /* color: #777; */
    line-height: 1.8;
  }
  
  /* Create a Parallax Effect */
  .parallax-effect {
      background-attachment: fixed; /* Set background image to fixed (don't scroll along with the page) */
      background-position: center; /* Center the background image */
      background-repeat: no-repeat; /* Set the background image to no repeat */
      background-size: cover; /* Scale the background image to be as large as possible */
  }
  
  /* home image */
  .img-home {
      background-image: url(/static/media/parallax3.4da51a14.jpg);
      min-height: 100vh; /* set minimum height to minimum 100% of device screen height */
  }
  
  .logo-normal {
      height: 350px;
      /* height: 300px; */
      /* margin-top: 8%; */
  }
  
  .logo-small {
      height: 150px;
  }
  
  .opacity-75 {
      /* opacity: 0.75; */
      opacity: 0.85;
  }
  
      /* Turn off parallax scrolling for tablets and phones */
      /* @media only screen and (max-device-width: 1600px) {
          .img-home {
          background-attachment: scroll;
          min-height: 400px;
          }
      } */
  
  
  .center {
      position: absolute; /* Absolute positioning means that the element is taken completely out of the normal flow of the page layout. As far as the rest of the elements on the page are concerned, the absolutely positioned element simply doesn't exist. The element itself is then drawn separately, sort of "on top" of everything else, at the position you specify using the left, right, top and bottom attributes. */
      top: 50%; /* position the top  edge of the element at the middle of the parent */
      left: 50%; /* position the left edge of the element at the middle of the parent */
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); /* so not the edges of the content is centered but the whole content */
      -ms-transform: translate(-50%, -50%); /* as above, but for Internet Explorer */
      -ms-transform: translate(-50%, -50%); /* as above, but for firefox */
  }
  
  @-webkit-keyframes opacityAnimation {
      from {
          opacity: 0 /* from 0 (opacity = 0%) */
      }
      to {
          opacity: 1 /* to 1 (opacity = 100%) */
      }
  }
  
  @keyframes opacityAnimation {
      from {
          opacity: 0 /* from 0 (opacity = 0%) */
      }
      to {
          opacity: 1 /* to 1 (opacity = 100%) */
      }
  }
  
  .animate-opacity {
      -webkit-animation: opacityAnimation 1s;
              animation: opacityAnimation 1s; /* we call our 'opacityAnimation' that we specified above */
  }
  
  

/****************************************************
********************* aboutUs ***********************
****************************************************/
.aboutUs {
    background-color: #917758;
    padding-left: 15px;
    padding-right: 15px;
}

.flex-wrap{
    display: flex;
    flex-wrap: wrap;
}

.aboutUs-content {
    /*Center content*/
    width: 100%; /* width should be 65% of the screen */
    max-width: 1170px; /* max 1170px (if using big screen) */
    margin: 0 auto;
    padding-bottom: 5rem;
}

/**************** left side ****************/

.about-left-side {
    /* margin-right: 6rem; */
    margin: 0 auto;
}

.rectangle-area {
    /* background-color: aqua; */
    width: 25rem; /* 50% of the 40% (the left side) will be the image */
    height: 50rem;
    position: relative;
    /*left: 11rem; /* left side of image will start from 45% of the width of left side (which is 40%) */
    top: -4em; /* Top side of image will be placed at '-3em', so it overlaps the screen above it */
}

.rectangle-area-mobile {
    /* background-color: aqua; */
    width: 22rem; /* 50% of the 40% (the left side) will be the image */
    height: 51rem;
    position: relative;
    /*left: 11rem; /* left side of image will start from 45% of the width of left side (which is 40%) */
    top: -4em; /* Top side of image will be placed at '-3em', so it overlaps the screen above it */
}

.rectangle-image {
    width: 100%; /* Image width is 100% of the rectangle are width */
    height: 100%; /* Image height is 100% of the rectangle are height */
}

.rectangle-content {
    background-color: rgba(0, 0, 0, 0.9);
    width: 85%; /* 95% of the rectangle-area width */
    height: 93%; /* 95% of the rectangle-area height */

    position: absolute; /* so we can define its positions using left & top */
    left: 7.5%; /* 100%-85%=15%    15%/2=7.5%  */
    top: 3.5%; /* 100%-93%=7%     7%/2=3.5%   */
}


/**************** right side ****************/

.about-right-side {
    font-size: 25px;
    position: relative;
    display: flex;
    margin: 0 auto;
    margin-top: 5rem;
}

.about-title-1 {
    color: white;
    font-family: 'Poppins', sans-serif;
}

.about-title-2 {
    color: white;
    font-family: 'Poppins', sans-serif;
    width: 30rem; 
}

.line:after {
    display: block;
    
    content: "";
    background: #a38f78;
    /* width: 70px; */
    width: 420px;
    height: 4px;

    margin-top: .5rem;
    margin-bottom: 2.5rem;
}

.text-left {
    color: #d2ccc5;
    max-width: 28rem;
    margin-right: 2em;
}

.p1 {
    font-size: 18px;
    margin: 1rem 0;
}

.p2 {
    font-size: 13px;
}

.p1-mobile {
    font-size: 18px;
    margin: 1rem 0;
    max-width: 22rem;
}

.p2-mobile {
    font-size: 13px;
    max-width: 22rem;
}

.brush-image {
    margin-top: 13rem;
    width: 7em; 
    opacity: 0.70;
}



/**************** rectangle content (left side) ****************/

.icon-area {
    width: 34px;
    margin: 0 auto;
    margin-top: 3rem;
}

.timing-icon-big {
    width: 50px;
    /* margin: 0 auto; */ /* not needed as it will fill the 'icon-area' */
}

.timing-icon {
    width: 35px;
    /* margin: 0 auto; */ /* not needed as it will fill the 'icon-area' */
}

.timing {
    text-align: center;
    color: #917758;
    font-size: 33px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.lineTiming:after {
    display: block;
    
    content: "";
    background: #917758;
    width: 16rem;
    height: 2px;
    
    margin: 0 auto;
}




.timing-title {
    margin-top: 2.8rem;
    color: #88837d;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 600;
}

.timing-content {
    color: white;
}




/****************************************************
********************* prices ***********************
****************************************************/


/*.prices {
    /* background-color: darkkhaki; */
/*}*/



/* home image */
.prices-content {
    text-align: center;
    background-color: #191919;
    padding-bottom: 5rem;
}

.img-prices {
    background-image: url(/static/media/parallax3.4da51a14.jpg);
    /*min-height: 100%; /* set minimum height to minimum 100% of device screen height */

    background: -webkit-gradient( linear, left top, left bottom, from(rgba(25, 25, 25, 0.9)), to(rgba(25, 25, 25, 0.9)) ), url(/static/media/parallax3.4da51a14.jpg);

    background: linear-gradient( rgba(25, 25, 25, 0.9), rgba(25, 25, 25, 0.9) ), url(/static/media/parallax3.4da51a14.jpg); /* Darkenimage */

    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Set the background image to no repeat */
    background-size: cover; /* Scale the background image to be as large as possible */
}



.price-title{
    Color: white;
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-top: 4rem;
    padding-bottom: 3rem;
}



.prices-container {
    justify-content: center;
}


.flex{
    display: flex;
}



.price-box {
    /*width: 50%; /* half of the screen */
    min-width: 440px;
    width: 600px;

    
    /*background-color: #ccc; /* Delete this later */
    padding: 0 15px; /* so boxes don't touch each other on the sides (left/right) */
    /*margin: 0px auto 30px auto; /* top, right, bottom, left */
    margin-bottom: 30px;
}

.price-box-mobile {
    /*width: 50%; /* half of the screen */
    min-width: 220px;
    width: 600px;

    
    /*background-color: #ccc; /* Delete this later */
    padding: 0 15px; /* so boxes don't touch each other on the sides (left/right) */
    /*margin: 0px auto 30px auto; /* top, right, bottom, left */
    margin-bottom: 20px;
}

.price-icon {
    width: 45px;
    /* margin: 0 auto; */ /* not needed as it will fill the 'icon-area' */
}

.price-icon-mobile {
    width: 35px;
    /* margin: 0 auto; */ /* not needed as it will fill the 'icon-area' */
}

.price-icon-big { /* for icons that needs to be migger (because they are small) */
    width: 55px;
    /* margin: 0 auto; */ /* not needed as it will fill the 'icon-area' */
}

.price-icon-small { /* for icons that needs to be smaller (because they are big) */
    margin-top: -15px;
    width: 35px;
    /* margin: 0 auto; */ /* not needed as it will fill the 'icon-area' */
}

.price-icon-small-mobile { /* for icons that needs to be smaller (because they are big) */
    margin-top: -15px;
    width: 30px;
    /* margin: 0 auto; */ /* not needed as it will fill the 'icon-area' */
}

.price-box-inner {
    /* height: 215px; */ /* Looks good when having long description */
    height: 150px;
    border: 3px solid #917758; /* a border for all 4 sides */
    /* display: table; */
    /* height: 100%; */
    /* table-layout: fixed; */
    /* width: 100%; */
    /* cursor: pointer; */
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s; /* takes 0.5 seconds to load stuff inside this element (ex. the hevring will now take 0.5s before changing background color, making it more smooth. */
}

.price-box-inner:hover {
    background-color: rgba(0,0,0,0.5);
}

.price-box-left {
    /* padding: 21px; */
    width: 70%; /* 70% price-box (which is 50% of screen) */
    border-right: 3px solid #917758;
}

.price-box-left-mobile {
    /* padding: 21px; */
    width: 90%; /* 70% price-box (which is 50% of screen) */
    border-right: 3px solid #917758;
}

.box-icon {
    /* float: left; */
    width: 15%; /* 15% of price-box-left (which is 70%) */
    padding-top: 1rem;
    text-align: center;
}

.box-icon-mobile {
    /* float: left; */
    width: 15%; /* 15% of price-box-left (which is 70%) */
    padding-top: 1rem;
    text-align: center;
}

.box-text {
    width: 85%; /* 85% of price-box-left (which is 70%)  */
    overflow: hidden;
    padding: 10px 10px;
}

.box-text.h4 {
    color: white;
}

.price-box-right {
    width: 30%; /* 30% price-box (which is 50% of screen) */
    margin: auto auto; /* center it horizontially and vertically */
    text-align: center;
}

.price-box-right-mobile {
    width: 20%; /* 30% price-box (which is 50% of screen) */
    margin: auto auto; /* center it horizontially and vertically */
    text-align: center;
}

.price-text {
    color: white;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1.5px;
}

.price-text-mobile {
    color: white;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1.5px;
}

.price-value {
    color: #917758;
    font-size: 28px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-left: 15px; /* The price will be 'more' in center looking away from the its ',-' icons */
}

.price-value-mobile {
    color: #917758;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-left: 15px; /* The price will be 'more' in center looking away from the its ',-' icons */
}

.service-title {
    color: white;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-top: 1rem;
}

.service-description {
    color: #969493;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 700;
    /* letter-spacing: 1.5px; */
}



/****************************************************
********************* Team ***********************
****************************************************/


.team {
    background-color: #917758;
    padding-bottom: 5rem;
} 


.team-title{
    Color: white;
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-top: 4rem;
    padding-bottom: 3rem;
}

.team-container {
    justify-content: center;
}

/******************** Left side ********************/

.team-left-side {
    height: 435px;
    margin-right: 3em;
    margin-bottom: 5rem;
}

.team-left-side-mobile {
    height: 435px;
    margin-right: 0em; /* keep it in the center for mobile view */
    margin-bottom: 5rem;
}

.overlay {
    /* background-color: rgba(255, 4, 4, 0.9); */
    opacity: 0; /* We make it invisible */
    height: 380px;
    width: 300px;
    
    position: relative;
    top: -1160px;

    -webkit-transition: all ease 0.5s;

    transition: all ease 0.5s; /* takes 0.5 seconds to load stuff inside this element (ex. the hevring will now take 0.5s before changing background color, making it more smooth. */
}

.overlay:hover {
    opacity: 1; /* We make it visible */
    background-color: rgba(0,0,0,0.7);
}

.person-description {
    padding: 30px 30px;
    Color: white;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding-bottom: 1rem;
}

.img-team {
    height: 435px;
    /*width: 20rem; */
    
    /*border: 3px solid rgba(255, 255, 255, 0.3); /* a border for all 4 sides */    
}

.white-borders {
    height: 400px;
    width: 260px;
    border: 4px solid rgb(255, 255, 255);

    position: relative;
    left: 20px;
    top: -425px;
}

.img-black-brush {
    width: 300px;
    position: relative;
    left: 0px;
    top: -525px;
}

.team-name-on-black-brush {
    Color: white;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding-bottom: 1rem;

    width: 298px;
    text-align: center;

    position: relative;
    top: -640px;
}

.team-position {
    Color: #917759;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding-bottom: 1rem;
    margin-bottom: 3rem;
}


/******************** right side ********************/

.team-right-side {
    height: 435px; /* same as image height */
    max-width: 28rem;
    background-color: rgba(0,0,0,0.1);
    padding: 1rem 1rem;
}
/*.text-left {
   
}*/
.team-name {
    Color: white;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding-bottom: 1rem;

}

.team-description {
    Color: white;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.5px;
}














.team-rectangle-area {
    background-color: aqua;
    width: 315px; /* 50% of the 40% (the left side) will be the image */
    height: 450px;/*435px;*/
    /* position: relative; */
    /*left: 11rem; /* left side of image will start from 45% of the width of left side (which is 40%) */
    /*top: -4em; /* Top side of image will be placed at '-3em', so it overlaps the screen above it */
}

.team-rectangle-image {
    width: 100%; /* Image width is 100% of the rectangle area width */
    height: 100%; /* Image height is 100% of the rectangle area height */

    /*background-color: aqua;
    width: 315px; /* 50% of the 40% (the left side) will be the image */
    /*height: 450px;*/
}





/****************************************************
********************* contact ***********************
****************************************************/


/*.contact {
    /* background-color: darkslategrey; */
    
/*}*/




/* home image */
.contact-content {
    /* padding-top: 5rem; */
    /* background-color: #191919; */
    padding-bottom: 5rem;
    /*height: 50em; /* Delete later */
}

.img-contact {
    /*filter: grayscale(100%); /* creates a black/white filter in chrome */
    background-image: url(/static/media/parallax3.4da51a14.jpg);
    /*min-height: 100%; /* set minimum height to minimum 100% of device screen height */
  
    background: -webkit-gradient( linear, left top, left bottom, from(rgba(25, 25, 25, 0.3)), to(rgba(25, 25, 25, 0.3)) ), url(/static/media/parallax3.4da51a14.jpg);
  
    background: linear-gradient( rgba(25, 25, 25, 0.3), rgba(25, 25, 25, 0.3) ), url(/static/media/parallax3.4da51a14.jpg); /* Darkenimage */

    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Set the background image to no repeat */
    background-size: cover; /* Scale the background image to be as large as possible */
}



.contact-title{
    Color: white;
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-top: 4rem;
    padding-bottom: 3rem;
}



.contact-container {
    justify-content: center;
}




/************ contact info *****************/

.contact-info {

    background-color: #191919;;
    width: 95%; /* width should be 95% of the total width */
    max-width: 80rem; /* width should be max 80rem even if width is less than 95% of the total width */

    margin: 0 auto;
    padding: 1.5rem 1.5rem;
    
    overflow: auto; /* so the height of the div cover all float elements inside it */
}

.info-data {
    width: 50%;
    margin-bottom: 2rem;
    
    float: left;
}

.info-icon-area {
    margin-top: .5rem;
    margin-right: 1rem;
}

.info-icon {
    width: 28px;
}

.info-icon-mobile {
    width: 20px;
}

.info-details span {
    color: #b3b3b3;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    /* font-weight: 700; */
    letter-spacing: 1.5px;
}

.info-details-mobile span {
    color: #b3b3b3;
    font-size: 10px;
    font-family: 'Poppins', sans-serif;
    /* font-weight: 700; */
    letter-spacing: 1.5px;
}

.info-details p {
    color: #f3f3f3;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    /* font-weight: 700; */
    letter-spacing: 1.5px;
}

.info-details-mobile p {
    color: #f3f3f3;
    font-size: 10px;
    font-family: 'Poppins', sans-serif;
    /* font-weight: 700; */
    letter-spacing: 1.5px;
}



/************ contact location *****************/


.contact-location {
    position: relative;
    width: 95%;
    margin: 0 auto;

    /* Grey the map (black/white) */
    /* filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
    /* filter: gray; /* IE6-9 */
    /* -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}




/****************************************************
********************* Footer ***********************
****************************************************/


.footer {
    background-color: #191919;
    /* height: 10rem; */
}




/* home image */
.footer-content {
    padding-top: 5rem;
    /* background-color: #191919; */
    padding-bottom: 5rem;
    /*height: 50em; /* Delete later */

    text-align: center;
}

.footer-text {
    color: #f3f3f3;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1.5px;
}

.footer-icons {
    /* margin-top: 1rem; */
    /* height: 10rem; */

    /* margin-left: 5rem; */
    width: 8rem;
    margin: 1rem auto;
}


.circle-border {
    border-radius: 100%;
    border: 1px solid #636d79;

    width: 45px;
    height: 45px;
    padding-top: 10px; /* so the icons will be more in center */

    margin-right: 1rem;

    cursor: pointer;

    -webkit-transition: all ease 0.5s;

    transition: all ease 0.5s;
}

.circle-border:hover {
    background-color: #917758;
}

.social-media-icon {
    height: 1.5rem;
    margin: auto auto;
}



.login-content {
    padding-top: 5rem;
    text-align: center;
}

/* **********************************************
********************* General *********************
********************************************** */
.font-this {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1.5px;
}

.font-this-small {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 0.9rem;
}

.font-this-smaller {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 0.7rem;
}

.center-this {
    text-align: center;
}

.center-flex {
    justify-content: center;
}


.renderNewLine {
    white-space: pre-line;
}

/* **********************************************
********************* Flexbox *********************
********************************************** */

.flex {
    display: flex; /* or inline-flex */
}

.inlineFlex {
    display: inline-flex;
}

.flexWrap {
    flex-wrap: wrap;
}

.flexEnd {
    justify-content: flex-end;
}

/* **********************************************
********************* validation *********************
********************************************** */

.errorMessage {
    font-size: 12px;
    color: red;
    /* font-weight: bold; */
    /* width: 25rem; */
}

.inputError {
    border-color: red;
}

.sucessMessage {
    font-size: 12px;
    color: green;
}

/* **********************************************
********************* AboutUs *********************
********************************************** */

.labelStyling {
    /* background-color: aqua; */
    width: 10rem;
    margin-right: .5rem;
}

.marginBottom {
    margin-bottom: .5rem;
}

.marginBottomBig {
    margin-bottom: 10rem;
}

.inputStyling {
    width: 15rem;
}


.save-button-padding {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
}


/* **********************************************
********************* Title *********************
********************************************** */
.admin-title{
    Color: rgb(0, 0, 0);
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-top: 5rem;
    padding-bottom: 3rem;
}


/* **********************************************
********************* Prices Table *********************
********************************************** */
.table-center {
    margin-left:auto; 
    margin-right:auto;
    /* border: 1px solid #000000; */
}

table, th, td {
    border-collapse: collapse;
    border: 1px solid #000000;
}

.tdd {
    max-width: 2;
}

.ellipsisName {
    max-width: 16rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ellipsisDescription {
    max-width: 30rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ellipsisPrice {
    max-width: 5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.table-button-padding {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 2.5px;
    margin-right: 2.5px;
}

.tableArea {
    max-width: 40rem;
    margin: 0 auto; /*center it*/
}

.addButton {
    width: 10rem;
    margin-right: 100%;
    margin-bottom: 1rem;
}

.centerServices {
    margin: auto;
    max-width: 80rem;
    justify-content: center;
}

.paddingLeft {
    padding-left: 0.5rem;
}

.paddingRight {
    padding-right: 0.5rem;
}

/* **********************************************
********************* add service Modal *********************
********************************************** */

.fade {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear;
}

.modal-container {
    /* display: block; */

    /* margin: 0 auto; */ /* we don't need this as we use position fixed */
    /* width: 10rem; */
    /* height: 15rem; */
    background-color: rgb(211, 211, 211);

    position: fixed;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    
    margin-bottom: 10rem;

    z-index: 2; /* The z-index property specifies the stack order of an element. Everything is on stack 0 (not sure), but for this modal we put it on 2, so it will be on top of everything. The background of the bodal will have the value 1, this is why the modal itself is 2. It should be placed on top of the background.
    NOTE: z-index only works on positioned elements (position: absolute, position: relative, position: fixed, or position: sticky). */
}

.modal-container-edit-employee {
    /* display: block; */

    /* margin: 0 auto; */ /* we don't need this as we use position fixed */
    /* width: 10rem; */
    /* height: 15rem; */
    background-color: rgb(211, 211, 211);

    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    
    margin-bottom: 10rem;

    z-index: 2; /* The z-index property specifies the stack order of an element. Everything is on stack 0 (not sure), but for this modal we put it on 2, so it will be on top of everything. The background of the bodal will have the value 1, this is why the modal itself is 2. It should be placed on top of the background.
    NOTE: z-index only works on positioned elements (position: absolute, position: relative, position: fixed, or position: sticky). */
}

.delete-modal-container {
    background-color: rgb(211, 211, 211);

    position: fixed;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    
    margin-bottom: 10rem;

    z-index: 2; /* The z-index property specifies the stack order of an element. Everything is on stack 0 (not sure), but for this modal we put it on 2, so it will be on top of everything. The background of the bodal will have the value 1, this is why the modal itself is 2. It should be placed on top of the background.
    NOTE: z-index only works on positioned elements (position: absolute, position: relative, position: fixed, or position: sticky). */
}


.makeMargin {
    margin-top: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
}

.modalButton {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    margin-left: 3rem;
}

.marginRight {
    margin-right: 1.5rem;
}

.paddingButton {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.iconArea {
    width: 16rem;
}

.iconRightMargin {
    margin-right: 1rem;
}

.multipleIcons {
    width: 3rem; /* we set a specific width so icons won't be placed next to each other but instead on each line (on top of each other) */
    margin-left: auto;
    margin-right: auto;
}

.centerRadioButton {
    margin: auto;
    width: 3rem;
}

.centerIcon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 2.5rem;
}

.greyOutBackground {
    background-color: rgb(0, 0, 0);
    position: absolute;

    /* make the width & heigh cover whole page */
    position:fixed; /* by using position as 'fixed', we will be able to cover the complete screen, even if we zoom out or scroll down */
    width:100%;
    height:100%;

    /* make the starting position from 0, 0 */
    top: 0%;
    left: 0%;

    z-index:1; /* The z-index property specifies the stack order of an element. Everything is on stack 0 (not sure), but for this modal we put it on 1, so it will be on top of everything
    NOTE: z-index only works on positioned elements (position: absolute, position: relative, position: fixed, or position: sticky). */

    -webkit-filter: opacity(80%);

            filter: opacity(80%);
    /* filter:blur(8px); */
}



/* **********************************************
********************* Team *********************
********************************************** */


.ellipsisTeamName {
    width: 8rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ellipsisTeamTitle {
    width: 10rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ellipsisTeamDescription {
    max-width: 30rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.marginRightSmall {
    margin-right: .5rem;
}
