

/****************************************************
********************* Team ***********************
****************************************************/


.team {
    background-color: #917758;
    padding-bottom: 5rem;
} 


.team-title{
    Color: white;
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-top: 4rem;
    padding-bottom: 3rem;
}

.team-container {
    justify-content: center;
}

/******************** Left side ********************/

.team-left-side {
    height: 435px;
    margin-right: 3em;
    margin-bottom: 5rem;
}

.team-left-side-mobile {
    height: 435px;
    margin-right: 0em; /* keep it in the center for mobile view */
    margin-bottom: 5rem;
}

.overlay {
    /* background-color: rgba(255, 4, 4, 0.9); */
    opacity: 0; /* We make it invisible */
    height: 380px;
    width: 300px;
    
    position: relative;
    top: -1160px;

    transition: all ease 0.5s; /* takes 0.5 seconds to load stuff inside this element (ex. the hevring will now take 0.5s before changing background color, making it more smooth. */
}

.overlay:hover {
    opacity: 1; /* We make it visible */
    background-color: rgba(0,0,0,0.7);
}

.person-description {
    padding: 30px 30px;
    Color: white;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding-bottom: 1rem;
}

.img-team {
    height: 435px;
    /*width: 20rem; */
    
    /*border: 3px solid rgba(255, 255, 255, 0.3); /* a border for all 4 sides */    
}

.white-borders {
    height: 400px;
    width: 260px;
    border: 4px solid rgb(255, 255, 255);

    position: relative;
    left: 20px;
    top: -425px;
}

.img-black-brush {
    width: 300px;
    position: relative;
    left: 0px;
    top: -525px;
}

.team-name-on-black-brush {
    Color: white;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding-bottom: 1rem;

    width: 298px;
    text-align: center;

    position: relative;
    top: -640px;
}

.team-position {
    Color: #917759;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding-bottom: 1rem;
    margin-bottom: 3rem;
}


/******************** right side ********************/

.team-right-side {
    height: 435px; /* same as image height */
    max-width: 28rem;
    background-color: rgba(0,0,0,0.1);
    padding: 1rem 1rem;
}
/*.text-left {
   
}*/
.team-name {
    Color: white;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding-bottom: 1rem;

}

.team-description {
    Color: white;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.5px;
}














.team-rectangle-area {
    background-color: aqua;
    width: 315px; /* 50% of the 40% (the left side) will be the image */
    height: 450px;/*435px;*/
    /* position: relative; */
    /*left: 11rem; /* left side of image will start from 45% of the width of left side (which is 40%) */
    /*top: -4em; /* Top side of image will be placed at '-3em', so it overlaps the screen above it */
}

.team-rectangle-image {
    width: 100%; /* Image width is 100% of the rectangle area width */
    height: 100%; /* Image height is 100% of the rectangle area height */

    /*background-color: aqua;
    width: 315px; /* 50% of the 40% (the left side) will be the image */
    /*height: 450px;*/
}

