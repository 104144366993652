


/****************************************************
********************* contact ***********************
****************************************************/


/*.contact {
    /* background-color: darkslategrey; */
    
/*}*/




/* home image */
.contact-content {
    /* padding-top: 5rem; */
    /* background-color: #191919; */
    padding-bottom: 5rem;
    /*height: 50em; /* Delete later */
}

.img-contact {
    /*filter: grayscale(100%); /* creates a black/white filter in chrome */
    background-image: url('../../../../images/parallax3.jpg');
    /*min-height: 100%; /* set minimum height to minimum 100% of device screen height */
  
    background: linear-gradient( rgba(25, 25, 25, 0.3), rgba(25, 25, 25, 0.3) ), url('../../../../images/parallax3.jpg'); /* Darkenimage */

    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Set the background image to no repeat */
    background-size: cover; /* Scale the background image to be as large as possible */
}



.contact-title{
    Color: white;
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-top: 4rem;
    padding-bottom: 3rem;
}



.contact-container {
    justify-content: center;
}




/************ contact info *****************/

.contact-info {

    background-color: #191919;;
    width: 95%; /* width should be 95% of the total width */
    max-width: 80rem; /* width should be max 80rem even if width is less than 95% of the total width */

    margin: 0 auto;
    padding: 1.5rem 1.5rem;
    
    overflow: auto; /* so the height of the div cover all float elements inside it */
}

.info-data {
    width: 50%;
    margin-bottom: 2rem;
    
    float: left;
}

.info-icon-area {
    margin-top: .5rem;
    margin-right: 1rem;
}

.info-icon {
    width: 28px;
}

.info-icon-mobile {
    width: 20px;
}

.info-details span {
    color: #b3b3b3;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    /* font-weight: 700; */
    letter-spacing: 1.5px;
}

.info-details-mobile span {
    color: #b3b3b3;
    font-size: 10px;
    font-family: 'Poppins', sans-serif;
    /* font-weight: 700; */
    letter-spacing: 1.5px;
}

.info-details p {
    color: #f3f3f3;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    /* font-weight: 700; */
    letter-spacing: 1.5px;
}

.info-details-mobile p {
    color: #f3f3f3;
    font-size: 10px;
    font-family: 'Poppins', sans-serif;
    /* font-weight: 700; */
    letter-spacing: 1.5px;
}



/************ contact location *****************/


.contact-location {
    position: relative;
    width: 95%;
    margin: 0 auto;

    /* Grey the map (black/white) */
    /* filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
    /* filter: gray; /* IE6-9 */
    /* -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}


