

/****************************************************
********************* Nav ***********************
****************************************************/

.nav {
    position: fixed; /* An element with position: fixed; is positioned relative to the viewport, which means it always stays in the same place even if the page is scrolled. The top, right, bottom, and left properties are used to position the element. */
    width: 100%; /* fills/stretches the whole width */
    z-index: 1; /* The z-index property specifies the stack order of an element. An element with greater stack order is always in front of an element with a lower stack order. Note: z-index only works on positioned elements (position: absolute, position: relative, position: fixed, or position: sticky).*/
    /* top: 0; */
    /* border-top: 6px solid #ccc!important; */
    /* text-align: center; */
}

.nav-on-scroll {
    color: #000!important;
    background-color: #fff!important;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}


.normal-nav {
    text-align: center;
    padding-top: .75em;
    padding-bottom: 1em;
    /* background-color: antiquewhite; */
}

.nav-btn {
    padding: 1em 1em;
    vertical-align: middle;
    overflow: hidden; /* The overflow is clipped, and the rest of the content will be invisible */
	text-decoration: none;
	color: #777; /* inherit its value from its parent element. */
	/*background-color: inherit; /* inherit its value from its parent element. */
    /*     
    -webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
     */
    white-space: normal; /*  Lines are broken as necessary to fill line boxes */

    transition: all ease 0.5s;
}

.nav-btn:disabled {
	cursor: not-allowed;
	opacity: 0.3
}

.nav-btn:hover {
	color: #000!important;
	background-color: #ccc!important
}

.right {
    float: right!important;
}


/******************* Mini nav **********************/

.mini-nav{
    text-align: left;
}

.mini-nav-btn {
    width: 100%;
    display: block;
    text-decoration: none;
    color: #777;
    /* background-color: inherit; */
    padding: 8px 16px;

    transition: all ease 0.5s;
}

.mini-nav-btn:hover {
    color: #000!important;
	background-color: #ccc!important
}

.links-hide {
	display: none!important
}

.links-show {
	display: block!important
}

.mini-nav-background {
	color: #000!important;
	background-color: #fff!important
}
