
/* **********************************************
********************* General *********************
********************************************** */
.font-this {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1.5px;
}

.font-this-small {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 0.9rem;
}

.font-this-smaller {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 0.7rem;
}

.center-this {
    text-align: center;
}

.center-flex {
    justify-content: center;
}


.renderNewLine {
    white-space: pre-line;
}

/* **********************************************
********************* Flexbox *********************
********************************************** */

.flex {
    display: flex; /* or inline-flex */
}

.inlineFlex {
    display: inline-flex;
}

.flexWrap {
    flex-wrap: wrap;
}

.flexEnd {
    justify-content: flex-end;
}

/* **********************************************
********************* validation *********************
********************************************** */

.errorMessage {
    font-size: 12px;
    color: red;
    /* font-weight: bold; */
    /* width: 25rem; */
}

.inputError {
    border-color: red;
}

.sucessMessage {
    font-size: 12px;
    color: green;
}

/* **********************************************
********************* AboutUs *********************
********************************************** */

.labelStyling {
    /* background-color: aqua; */
    width: 10rem;
    margin-right: .5rem;
}

.marginBottom {
    margin-bottom: .5rem;
}

.marginBottomBig {
    margin-bottom: 10rem;
}

.inputStyling {
    width: 15rem;
}


.save-button-padding {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
}


/* **********************************************
********************* Title *********************
********************************************** */
.admin-title{
    Color: rgb(0, 0, 0);
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-top: 5rem;
    padding-bottom: 3rem;
}


/* **********************************************
********************* Prices Table *********************
********************************************** */
.table-center {
    margin-left:auto; 
    margin-right:auto;
    /* border: 1px solid #000000; */
}

table, th, td {
    border-collapse: collapse;
    border: 1px solid #000000;
}

.tdd {
    max-width: 2;
}

.ellipsisName {
    max-width: 16rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ellipsisDescription {
    max-width: 30rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ellipsisPrice {
    max-width: 5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.table-button-padding {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 2.5px;
    margin-right: 2.5px;
}

.tableArea {
    max-width: 40rem;
    margin: 0 auto; /*center it*/
}

.addButton {
    width: 10rem;
    margin-right: 100%;
    margin-bottom: 1rem;
}

.centerServices {
    margin: auto;
    max-width: 80rem;
    justify-content: center;
}

.paddingLeft {
    padding-left: 0.5rem;
}

.paddingRight {
    padding-right: 0.5rem;
}

/* **********************************************
********************* add service Modal *********************
********************************************** */

.fade {
    transition: opacity .15s linear;
}

.modal-container {
    /* display: block; */

    /* margin: 0 auto; */ /* we don't need this as we use position fixed */
    /* width: 10rem; */
    /* height: 15rem; */
    background-color: rgb(211, 211, 211);

    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    margin-bottom: 10rem;

    z-index: 2; /* The z-index property specifies the stack order of an element. Everything is on stack 0 (not sure), but for this modal we put it on 2, so it will be on top of everything. The background of the bodal will have the value 1, this is why the modal itself is 2. It should be placed on top of the background.
    NOTE: z-index only works on positioned elements (position: absolute, position: relative, position: fixed, or position: sticky). */
}

.modal-container-edit-employee {
    /* display: block; */

    /* margin: 0 auto; */ /* we don't need this as we use position fixed */
    /* width: 10rem; */
    /* height: 15rem; */
    background-color: rgb(211, 211, 211);

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    margin-bottom: 10rem;

    z-index: 2; /* The z-index property specifies the stack order of an element. Everything is on stack 0 (not sure), but for this modal we put it on 2, so it will be on top of everything. The background of the bodal will have the value 1, this is why the modal itself is 2. It should be placed on top of the background.
    NOTE: z-index only works on positioned elements (position: absolute, position: relative, position: fixed, or position: sticky). */
}

.delete-modal-container {
    background-color: rgb(211, 211, 211);

    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    margin-bottom: 10rem;

    z-index: 2; /* The z-index property specifies the stack order of an element. Everything is on stack 0 (not sure), but for this modal we put it on 2, so it will be on top of everything. The background of the bodal will have the value 1, this is why the modal itself is 2. It should be placed on top of the background.
    NOTE: z-index only works on positioned elements (position: absolute, position: relative, position: fixed, or position: sticky). */
}


.makeMargin {
    margin-top: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
}

.modalButton {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    margin-left: 3rem;
}

.marginRight {
    margin-right: 1.5rem;
}

.paddingButton {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.iconArea {
    width: 16rem;
}

.iconRightMargin {
    margin-right: 1rem;
}

.multipleIcons {
    width: 3rem; /* we set a specific width so icons won't be placed next to each other but instead on each line (on top of each other) */
    margin-left: auto;
    margin-right: auto;
}

.centerRadioButton {
    margin: auto;
    width: 3rem;
}

.centerIcon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 2.5rem;
}

.greyOutBackground {
    background-color: rgb(0, 0, 0);
    position: absolute;

    /* make the width & heigh cover whole page */
    position:fixed; /* by using position as 'fixed', we will be able to cover the complete screen, even if we zoom out or scroll down */
    width:100%;
    height:100%;

    /* make the starting position from 0, 0 */
    top: 0%;
    left: 0%;

    z-index:1; /* The z-index property specifies the stack order of an element. Everything is on stack 0 (not sure), but for this modal we put it on 1, so it will be on top of everything
    NOTE: z-index only works on positioned elements (position: absolute, position: relative, position: fixed, or position: sticky). */

    filter: opacity(80%);
    /* filter:blur(8px); */
}



/* **********************************************
********************* Team *********************
********************************************** */


.ellipsisTeamName {
    width: 8rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ellipsisTeamTitle {
    width: 10rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ellipsisTeamDescription {
    max-width: 30rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.marginRightSmall {
    margin-right: .5rem;
}