

/****************************************************
********************* prices ***********************
****************************************************/


/*.prices {
    /* background-color: darkkhaki; */
/*}*/



/* home image */
.prices-content {
    text-align: center;
    background-color: #191919;
    padding-bottom: 5rem;
}

.img-prices {
    background-image: url('../../../../images/parallax3.jpg');
    /*min-height: 100%; /* set minimum height to minimum 100% of device screen height */

    background: linear-gradient( rgba(25, 25, 25, 0.9), rgba(25, 25, 25, 0.9) ), url('../../../../images/parallax3.jpg'); /* Darkenimage */

    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Set the background image to no repeat */
    background-size: cover; /* Scale the background image to be as large as possible */
}



.price-title{
    Color: white;
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-top: 4rem;
    padding-bottom: 3rem;
}



.prices-container {
    justify-content: center;
}


.flex{
    display: flex;
}



.price-box {
    /*width: 50%; /* half of the screen */
    min-width: 440px;
    width: 600px;

    
    /*background-color: #ccc; /* Delete this later */
    padding: 0 15px; /* so boxes don't touch each other on the sides (left/right) */
    /*margin: 0px auto 30px auto; /* top, right, bottom, left */
    margin-bottom: 30px;
}

.price-box-mobile {
    /*width: 50%; /* half of the screen */
    min-width: 220px;
    width: 600px;

    
    /*background-color: #ccc; /* Delete this later */
    padding: 0 15px; /* so boxes don't touch each other on the sides (left/right) */
    /*margin: 0px auto 30px auto; /* top, right, bottom, left */
    margin-bottom: 20px;
}

.price-icon {
    width: 45px;
    /* margin: 0 auto; */ /* not needed as it will fill the 'icon-area' */
}

.price-icon-mobile {
    width: 35px;
    /* margin: 0 auto; */ /* not needed as it will fill the 'icon-area' */
}

.price-icon-big { /* for icons that needs to be migger (because they are small) */
    width: 55px;
    /* margin: 0 auto; */ /* not needed as it will fill the 'icon-area' */
}

.price-icon-small { /* for icons that needs to be smaller (because they are big) */
    margin-top: -15px;
    width: 35px;
    /* margin: 0 auto; */ /* not needed as it will fill the 'icon-area' */
}

.price-icon-small-mobile { /* for icons that needs to be smaller (because they are big) */
    margin-top: -15px;
    width: 30px;
    /* margin: 0 auto; */ /* not needed as it will fill the 'icon-area' */
}

.price-box-inner {
    /* height: 215px; */ /* Looks good when having long description */
    height: 150px;
    border: 3px solid #917758; /* a border for all 4 sides */
    /* display: table; */
    /* height: 100%; */
    /* table-layout: fixed; */
    /* width: 100%; */
    /* cursor: pointer; */
    transition: all ease 0.5s; /* takes 0.5 seconds to load stuff inside this element (ex. the hevring will now take 0.5s before changing background color, making it more smooth. */
}

.price-box-inner:hover {
    background-color: rgba(0,0,0,0.5);
}

.price-box-left {
    /* padding: 21px; */
    width: 70%; /* 70% price-box (which is 50% of screen) */
    border-right: 3px solid #917758;
}

.price-box-left-mobile {
    /* padding: 21px; */
    width: 90%; /* 70% price-box (which is 50% of screen) */
    border-right: 3px solid #917758;
}

.box-icon {
    /* float: left; */
    width: 15%; /* 15% of price-box-left (which is 70%) */
    padding-top: 1rem;
    text-align: center;
}

.box-icon-mobile {
    /* float: left; */
    width: 15%; /* 15% of price-box-left (which is 70%) */
    padding-top: 1rem;
    text-align: center;
}

.box-text {
    width: 85%; /* 85% of price-box-left (which is 70%)  */
    overflow: hidden;
    padding: 10px 10px;
}

.box-text.h4 {
    color: white;
}

.price-box-right {
    width: 30%; /* 30% price-box (which is 50% of screen) */
    margin: auto auto; /* center it horizontially and vertically */
    text-align: center;
}

.price-box-right-mobile {
    width: 20%; /* 30% price-box (which is 50% of screen) */
    margin: auto auto; /* center it horizontially and vertically */
    text-align: center;
}

.price-text {
    color: white;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1.5px;
}

.price-text-mobile {
    color: white;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1.5px;
}

.price-value {
    color: #917758;
    font-size: 28px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-left: 15px; /* The price will be 'more' in center looking away from the its ',-' icons */
}

.price-value-mobile {
    color: #917758;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-left: 15px; /* The price will be 'more' in center looking away from the its ',-' icons */
}

.service-title {
    color: white;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-top: 1rem;
}

.service-description {
    color: #969493;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 700;
    /* letter-spacing: 1.5px; */
}
